<template>
  <v-dialog v-model="dialogEdit" max-width="530px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item class="apListItem" v-on="on" @click="fetchSubServices">
        <v-icon
          class="mr-2"
          small
          style="margin-top: 0px !important"
          dark
          icon
          size="18"
          v-bind="attrs"
        >
          $edit
        </v-icon>
        <span> {{ $t("edit", { name: $tc("") }) }}</span>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>{{ $t("edit", { name: pack.description }) }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialogEdit = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row class="py-0">
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.description") }}</label>
            <v-text-field
              dense
              outlined
              hide-details
              v-model="pack.description"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.price") }}</label>
            <v-text-field
              v-model="editPack.price"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.real_price") }}</label>
            <v-text-field
              dense
              hide-details
              disabled
              :value="real_price()"
              outlined
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-simple-table background-color="transparent" class="my-0 ml-2">
        <thead>
          <tr>
            <th style="color: var(--v-primary-base) ">
              {{ $t("packs.sub_artist_type") }}
            </th>
            <th style="color: var(--v-primary-base)">
              {{ $tc("quantity", 1) }}
            </th>
            <th style="color: var(--v-primary-base)">
              {{ $tc("price", 1) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sub_artist_type, i) in editPack.sub_artist_type" :key="i">
            <td class="pt-0">
              <v-autocomplete
                :items="sub_artist_types"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                hide-details
                size="15"
                style="padding-top: 0 !important"
                v-model="sub_artist_type.sub_artist_type_id"
                :item-text="(x) => `${x.reference} - ${x.description}`"
                item-value="id"
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                dense
                outlined
                type="number"
                hide-details
                v-model="sub_artist_type.quantity"
                style="padding-top: 0 !important"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                dense
                outlined
                hide-details
                disabled
                :value="price(sub_artist_type)"
                style="padding-top: 0 !important"
              ></v-text-field>
            </td>
            <td>
              <v-btn icon @click="del(i)" style="margin-left: -20px">
                <v-icon size="18">$delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <v-btn
                x-small
                fab
                outlined
                @click="add"
                style="margin-left: -10px"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="dialogEdit = false"
          style="height: 25px"
          elevation="0"
          class="mr-3"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          type="submit"
          style="
            width: 100px !important;
            color: #363533;
            height: 25px;
            padding-top: 10px !important;
          "
          @click="save"
          elevation="0"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: $t("") }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditPack",
  props: ["pack"],
  data() {
    return {
      dialogEdit: false,
      sub_artist_types: [],
      editedIndex: -1,
      defaultPack: {
        description: "",
        price: null,
        sub_artist_type: [{}],
        quantity: null,
      },
      editPack: {
        sub_artist_type: [{}],
      },
    };
  },
  methods: {
    real_price() {
      console.log("REAL PRICE");
      let price = 0;

      //GET TOTAL PRICE OF AL SUBSERVICES ADDED
      this.editPack.sub_artist_type.forEach((sub) => {
        console.log("SUB1", sub);
        let sat = this.sub_artist_types.find(
          (sat) => sat.id == sub.sub_artist_type_id
        );
        console.log("SAT", sat);
        console.log("SUB2", sub);
        if (sat && sub.quantity) {
          price += sub.quantity * sat.price;
        } else {
          price = 0;
        }
      });
      return price;
    },
    price(sub_artist_type) {
      let sat = this.sub_artist_types.find(
        (sat) => sat.id == sub_artist_type.sub_artist_type_id
      );
      if (sat && sub_artist_type.quantity) {
        console.log("sat", sat, sub_artist_type);
        return this.$n(sat.price * sub_artist_type.quantity, "currency");
      }
    },

    ...mapActions("subservices", ["getSubServices"]),
    fetchSubServices() {
      console.log("FETCH");
      this.loading = true;
      Object.keys(this.pack).forEach((key) => {
        switch (key) {
          case "sub_artist_type":
            this.editPack.sub_artist_type = this.pack.sub_artist_type.map(
              (x) => {
                return {
                  sub_artist_type_id: x.id,
                  quantity: x.pivot.quantity,
                };
              }
            );
            break;
          default:
            this.editPack[key] = this.pack[key];
        }
      });

      this.getSubServices({
        filters: {},
        pagination: { itemsPerPage: -1 },
        service_id: "all",
      }).then((subservices) => {
        this.sub_artist_types = subservices.data;
        console.log("SA", this.sub_artist_types);
      });
    },
    ...mapActions("packs", ["updatePack"]),
    save() {
      console.log(this.pack.id);
      console.log(this.pack);
      this.updatePack({
        id: this.pack.id,
        pack: this.editPack,
      }).then((response) => {
        if (response.success) {
          console.log(response);
          this.dialogEdit = false;
          this.$emit("update");
          this.$alert(this.$t("save_ok"));
        } else {
          console.log("ERROR");
        }
      });
      //this.services.push(this.editedSubservice);
    },

    add() {
      this.editPack.sub_artist_type.push({});
    },
    del(index) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() =>
        this.editPack.sub_artist_type.splice(index, 1)
      );
    },
  },
};
</script>

<style scoped lang="sass">
#cardEdit

.v-data-table.theme--dark tr th
  color: var(--v-primary-base) !important
  font-size: 12px !important
label
  display: block
  height: 25px
</style>
